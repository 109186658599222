import { reactive } from "vue"
import { defineStore } from "pinia"
import { IUser } from "@/common/interfaces/user/IUser"

export const useAuthStore = defineStore("authStore", () => {
  const loggedInUser = reactive<IUser>({
    userName: "",
    name: "",
    email: "",
    company: "",
    bpn: "",
    tenant: ""
  })

  return {
    loggedInUser
  }
})
