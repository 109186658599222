// defines route path and pathname declaration
export const ProtectedRoutes = {
  home: {
    path: "/home",
    pathName: "home"
  },
  manage: {
    externalConnection: {
      path: "external-connection-manager",
      pathName: "external-connection-manager"
    },
    requestConnection: {
      path: "request-external-connection",
      pathName: "request-external-connection"
    }
  },
  provider: {
    configure: {
      path: "",
      pathName: "configure"
    },
    success: {
      path: "success",
      pathName: "create-success"
    },
    uploadHistory: {
      path: "upload-history",
      pathName: "upload-history"
    }
  },
  consume: {
    offers: {
      path: "offers",
      pathName: "offers"
    },
    contracts: {
      path: "contracts",
      pathName: "contracts"
    }
  },
  prepare: {
    models: {
      path: "models",
      pathName: "models"
    },
    mapping: {
      path: "mapping",
      pathName: "mapping"
    },
    converter: {
      path: "converter",
      pathName: "converter"
    }
  },
  govern: {
    policy: {
      path: "policies",
      pathName: "policies"
    },
    subscribedAgreements: {
      path: "subscribed-agreements",
      pathName: "subscribed-agreements"
    },
    outgoingAgreements: {
      path: "outgoing-agreements",
      pathName: "outgoing-agreements"
    }
  },
  jobs: {
    path: "/jobs",
    pathName: "jobs"
  }
}
