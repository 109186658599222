import { useAuthStore } from "@/store/modules/auth/authStore"
import Keycloak from "keycloak-js"
import getEnv from "../util/env"
import { IUser } from "../interfaces/user/IUser"
import { storeToRefs } from "pinia"

const KEYCLOAK_URL = getEnv("VITE_APP_KEYCLOAK_URL")
const KEYCLOAK_CLIENT_ID = getEnv("VITE_APP_KEYCLOAK_CLIENT_ID")
const DEFAULT_KEYCLOAK_REALM = getEnv("VITE_APP_DEFAULT_KEYCLOAK_REALM")

const keycloakConfig: Keycloak.KeycloakConfig = {
  url: KEYCLOAK_URL,
  realm: DEFAULT_KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID
}
const KC = new Keycloak(keycloakConfig)

const doLogin = KC.login

const doLogout = KC.logout

const getToken = () => KC.token

const getParsedToken = () => KC.tokenParsed

const updateToken = (successCallback?: () => void) =>
  KC.updateToken(5).then(successCallback).catch(doLogin)

const getUsername = () => KC.tokenParsed?.preferred_username

const getName = () => KC.tokenParsed?.name

const getEmail = () => KC.tokenParsed?.email

const getCompany = () => KC.tokenParsed?.organisation

const getBpn = () => KC.tokenParsed?.bpn

const getTenant = () => KC.tokenParsed?.tenant

const hasRole = (roles: string[]) => roles.some((role: string) => KC.hasRealmRole(role))

const isLoggedIn = () => !!KC.token

const hasValidResource = () =>
  KC.tokenParsed?.resource_access?.hasOwnProperty(keycloakConfig.clientId)

const getLoggedUser = () => ({
  userName: getUsername(),
  name: getName(),
  email: getEmail(),
  company: getCompany(),
  bpn: getBpn(),
  tenant: getTenant()
})

const update = () => {
  KC.updateToken(5)
    .then((refreshed: boolean) => {
      console.log(`${getUsername()} token refreshed ${refreshed}`)
    })
    .catch(() => {
      console.log(`${getUsername()} token refresh failed`)
    })
}

const initKeycloak = (onAuthenticatedCallback: (loggedUser: IUser) => void) => {
  KC.init({
    onLoad: "login-required",
    pkceMethod: "S256",
    enableLogging: true
  })
    .then((authenticated) => {
      const { loggedInUser } = storeToRefs(useAuthStore())
      if (authenticated) {
        onAuthenticatedCallback(getLoggedUser())
        loggedInUser.value = getLoggedUser()
      } else {
        console.log(`${getUsername()} authentication failed`)
      }
    })
    .catch((err) => console.log(err))
}

KC.onTokenExpired = () => {
  console.log(`${getUsername()} token expired`)
  update()
}

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getParsedToken,
  updateToken,
  getUsername,
  getEmail,
  hasRole,
  getLoggedUser,
  hasValidResource
}

export default AuthService
